import { NeonInvoker } from '@cityofzion/neon-invoker'
import { NetworkType } from '@cityofzion/wallet-connect-sdk-react'
import { FCLAdapter, FCLOptions, FlowAuthenticator, FlowNetwork, Neo3Authenticator } from 'letter-sdk'

export type Network = {
  WcIdentifier: NetworkType
  N3RpcAddress: string
  N3AuthenticatorScriptHash: string
  fclAdapterOptions: FCLOptions
  flowNetwork: FlowNetwork
}

export const Networks: Record<string, Network> = {
  PRIVATE: {
    WcIdentifier: 'neo3:private',
    N3RpcAddress: 'http://127.0.0.1:50012',
    N3AuthenticatorScriptHash: '0x2c92c3eb049fbf159c73dd0eaab33ceebd99b952',
    fclAdapterOptions: {
      ...FCLAdapter.EMULATOR,
      appTitle: 'Cacilds',
      appImage: 'https://upload.wikimedia.org/wikipedia/pt/c/c3/Mussum_%28Ant%C3%B4nio_Carlos_Bernardes_Gomes%29.jpg',
    },
    flowNetwork: FlowAuthenticator.EMULATOR,
  },
  TESTNET: {
    WcIdentifier: 'neo3:testnet',
    N3RpcAddress: NeonInvoker.TESTNET,
    N3AuthenticatorScriptHash: Neo3Authenticator.TESTNET,
    fclAdapterOptions: {
      ...FCLAdapter.TESTNET,
      appTitle: 'Cacilds',
      appImage: 'https://upload.wikimedia.org/wikipedia/pt/c/c3/Mussum_%28Ant%C3%B4nio_Carlos_Bernardes_Gomes%29.jpg',
    },
    flowNetwork: FlowAuthenticator.TESTNET,
  },
  MAINNET: {
    WcIdentifier: 'neo3:mainnet',
    N3RpcAddress: NeonInvoker.MAINNET,
    N3AuthenticatorScriptHash: Neo3Authenticator.MAINNET,
    fclAdapterOptions: {
      ...FCLAdapter.MAINNET,
      appTitle: 'Cacilds',
      appImage: 'https://upload.wikimedia.org/wikipedia/pt/c/c3/Mussum_%28Ant%C3%B4nio_Carlos_Bernardes_Gomes%29.jpg',
    },
    flowNetwork: FlowAuthenticator.MAINNET,
  },
}

export const NETWORK = process.env.REACT_APP_NETWORK == 'main' ? Networks.MAINNET : Networks.TESTNET
