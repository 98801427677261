import { BrowserRouter, Route, Routes as RRDRoutes } from 'react-router-dom'

import { AdminPage } from './pages/Admin'

export const Routes = () => {
  return (
    <BrowserRouter>
      <RRDRoutes>
        <Route path="/" element={<AdminPage />} />
      </RRDRoutes>
    </BrowserRouter>
  )
}
