import { ChakraProvider } from '@chakra-ui/react'
import { WalletConnectProvider } from '@cityofzion/wallet-connect-sdk-react'

import { FlowProvider } from './contexts/flow'
import Fonts from './Fonts'
import { Routes } from './Routes'

const wcOptions = {
  projectId: '56e6c49cc5322f46778cd12dd69eeeff',
  relayUrl: 'wss://relay.walletconnect.com',
  metadata: {
    name: 'The Daily Planet',
    description: "Metropolis' Greatest Newspaper",
    url: 'https://letter.coz.io',
    icons: ['https://static.wikia.nocookie.net/earth279527/images/e/ea/Daily_Planet_Logo.png'],
  },
}

export default function App() {
  return (
    <ChakraProvider>
      <Fonts />
      <WalletConnectProvider autoManageSession={true} options={wcOptions}>
        <FlowProvider>
          <Routes />
        </FlowProvider>
      </WalletConnectProvider>
    </ChakraProvider>
  )
}
