import { FCLAdapter } from 'letter-sdk'
import { createContext, useCallback, useEffect, useRef, useState } from 'react'

import { NETWORK } from '../constants/blockchain'
import { FlowContextData, FlowContextProps } from '../types/contexts/flow'

const FlowContext = createContext<FlowContextData>({} as FlowContextData)

export const FlowProvider = ({ children }: FlowContextProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [address, setAddress] = useState<string | undefined>(undefined)

  const adapter = useRef<FCLAdapter>(new FCLAdapter(NETWORK.fclAdapterOptions))

  const connect = useCallback(async () => {
    await adapter.current.authenticate()
  }, [])

  const disconnect = useCallback(async () => {
    await adapter.current.unauthenticate()
  }, [])

  useEffect(() => {
    adapter.current.authSubscribe(user => {
      if (!user || !user.addr) {
        setIsAuthenticated(false)
        setAddress(undefined)
        return
      }

      setIsAuthenticated(true)
      setAddress(user.addr)
    })
  }, [])

  return (
    <FlowContext.Provider value={{ isAuthenticated, address, connect, disconnect, adapter: adapter.current }}>
      {children}
    </FlowContext.Provider>
  )
}

export default FlowContext
