import { NeonEventListener } from '@cityofzion/neon-event-listener'
import { NeonInvoker } from '@cityofzion/neon-invoker'
import { NeonParser } from '@cityofzion/neon-parser'
import { NeonSigner } from '@cityofzion/neon-signer'
import { useWalletConnect } from '@cityofzion/wallet-connect-sdk-react'
import { FlowAuthenticator, IAuthenticator, MultiAuthenticator, Neo3Authenticator } from 'letter-sdk'
import { useCallback } from 'react'

import { NETWORK } from '../constants/blockchain'
import { useFlow } from './useFlow'

export const useMultiAuthenticator = () => {
  const walletConnectCtx = useWalletConnect()
  const flowCtx = useFlow()

  const getMultiAuthenticator = useCallback(async () => {
    const authenticators: IAuthenticator<any>[] = []

    const walletConnectIsConnected = walletConnectCtx.isConnected()

    authenticators.push(
      new Neo3Authenticator(
        {
          invoker: walletConnectIsConnected ? walletConnectCtx : await NeonInvoker.init(NETWORK.N3RpcAddress),
          parser: NeonParser,
          signer: walletConnectIsConnected ? walletConnectCtx : new NeonSigner(),
          eventListener: new NeonEventListener(NETWORK.N3RpcAddress),
        },
        NETWORK.N3AuthenticatorScriptHash
      )
    )

    authenticators.push(new FlowAuthenticator(flowCtx.adapter, NETWORK.flowNetwork))

    return new MultiAuthenticator(authenticators)
  }, [walletConnectCtx, flowCtx])

  return {
    getMultiAuthenticator,
  }
}
