import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Heading,
  Modal, ModalBody, ModalCloseButton,
  ModalContent, ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spinner,
  Text,
  useToast
} from "@chakra-ui/react";
import { useWalletConnect } from '@cityofzion/wallet-connect-sdk-react'
import { Neo3Authenticator, TxRef } from "letter-sdk"
import { useCallback, useEffect, useRef, useState } from 'react'

import { NETWORK } from '../constants/blockchain'
import { useFlow } from '../hooks/useFlow'
import { useMultiAuthenticator } from '../hooks/useMultiAuthenticator'
import { fireSwal, TInputText } from '../utils/fireSwal'

export const AdminPage = () => {
  const walletConnectCtx = useWalletConnect()
  const flowCtx = useFlow()
  const toast = useToast()
  const { getMultiAuthenticator } = useMultiAuthenticator()

  const [resp, setResp] = useState('')
  const [loading, setLoading] = useState(false)
  const [contentProviders, setContentProviders] = useState<string[]>([])
  const [allTokens, setAllTokens] = useState<string[]>([])

  const alreadyStarted = useRef(false)

  const startListeners = useCallback(async () => {
    const authenticator = await getMultiAuthenticator()
    authenticator.transferListener(console.log)
  }, [getMultiAuthenticator])

  const populateContentProviders = useCallback(async () => {
    const authenticator = await getMultiAuthenticator()
    const contentProviders = await authenticator.listContentProvidersAsArray()
    setContentProviders(contentProviders)
  }, [getMultiAuthenticator])

  const populateTokens = useCallback(async () => {
    const authenticator = await getMultiAuthenticator()
    const tokens = await authenticator.tokensAsArray()

    setAllTokens(tokens.map(token => token.id))
  }, [getMultiAuthenticator])

  const prepareAdmin = useCallback(async () => {
    try {
      setLoading(true)

      const authenticator = await getMultiAuthenticator()

      const tx = await authenticator.prepareAdmin()

      setResp(JSON.stringify(tx, null, 2))
    } catch (error: any) {
      toast({ title: error.message, status: 'error' })
    } finally {
      setLoading(false)
    }
  }, [getMultiAuthenticator, toast])

  const destroyAdminContainer = useCallback(async () => {
    try {
      setLoading(true)

      const txId = await flowCtx.adapter.mutate({
        cadence: `
          import Authenticator from ${NETWORK.flowNetwork.Authenticator}

          transaction() {
          
              prepare(signer: AuthAccount) {
                  let res <- signer.load<@AnyResource>(from: Authenticator.adminContainerStoragePath)!
                  destroy res
              }
          }

        `,
        args: []
      })

      setResp(JSON.stringify({ txId }, null, 2))
    } catch (error: any) {
      toast({ title: error.message, status: 'error' })
    } finally {
      setLoading(false)
    }
  }, [toast, flowCtx.adapter])

  const prepareContentProvider = useCallback(async () => {
    try {
      setLoading(true)

      const authenticator = await getMultiAuthenticator()

      const tx = await authenticator.prepareContentProvider()

      setResp(JSON.stringify(tx, null, 2))
    } catch (error: any) {
      toast({ title: error.message, status: 'error' })
    } finally {
      setLoading(false)
    }
  }, [getMultiAuthenticator, toast])

  const destroyCpCollection = useCallback(async () => {
    try {
      setLoading(true)

      const txId = await flowCtx.adapter.mutate({
        cadence: `
          import Authenticator from ${NETWORK.flowNetwork.Authenticator}

          transaction() {
          
              prepare(signer: AuthAccount) {
                  let res <- signer.load<@AnyResource>(from: Authenticator.cpCollectionStoragePath)!
                  destroy res
              }
          }

        `,
        args: []
      })

      setResp(JSON.stringify({ txId }, null, 2))
    } catch (error: any) {
      toast({ title: error.message, status: 'error' })
    } finally {
      setLoading(false)
    }
  }, [toast, flowCtx.adapter])

  const destroyLicenseCollection = useCallback(async () => {
    try {
      setLoading(true)

      const txId = await flowCtx.adapter.mutate({
        cadence: `
          import Authenticator from ${NETWORK.flowNetwork.Authenticator}

          transaction() {
          
              prepare(signer: AuthAccount) {
                  let res <- signer.load<@AnyResource>(from: Authenticator.licenseCollectionStoragePath)!
                  destroy res
              }
          }

        `,
        args: []
      })

      setResp(JSON.stringify({ txId }, null, 2))
    } catch (error: any) {
      toast({ title: error.message, status: 'error' })
    } finally {
      setLoading(false)
    }
  }, [toast, flowCtx.adapter])

  const registerAdminAddresses = useCallback(async () => {
    try {

      const [address, dapperAdminAddress] = await fireSwal({
        title: 'Transfer Admin Rights',
        inputs: [
          { label: 'Address', type: 'text' },
          { label: 'Dapper Admin Address', type: 'text' }
        ],
      })

      setLoading(true)

      const txId = await flowCtx.adapter.mutate({
        cadence: `
          import Authenticator from ${NETWORK.flowNetwork.Authenticator}

          transaction(recipient: Address, adminDapperWalletAddress: Address) {
          
              prepare(signer: AuthAccount) {                        
                  let adminContainer = signer.borrow<&Authenticator.AdminContainer>(from: Authenticator.adminContainerStoragePath)
                                          ?? panic("No Admin Container")
                                          
                  let admin = adminContainer.borrowAdmin()
                  admin.registerAdmin(adminAddress: recipient, adminDapperWalletAddress: adminDapperWalletAddress)
              }
          }
        `,
        args: [
          { type: 'Address', value: address},
          { type: 'Address', value: dapperAdminAddress}
        ]
      })

      setResp(JSON.stringify({ txId }, null, 2))
    } catch (error: any) {
      toast({ title: error.message, status: 'error' })
    } finally {
      setLoading(false)
    }
  }, [toast, flowCtx.adapter])

  const prepareNFTOwner = useCallback(async () => {
    try {
      setLoading(true)

      const authenticator = await getMultiAuthenticator()

      const tx = await authenticator.prepareNFTOwner()

      setResp(JSON.stringify(tx, null, 2))
    } catch (error: any) {
      toast({ title: error.message, status: 'error' })
    } finally {
      setLoading(false)
    }
  }, [getMultiAuthenticator, toast])

  const getNeo3PublicKey = useCallback(async () => {
    try {
      setLoading(true)

      const authenticator = await getMultiAuthenticator()

      const neoAuthenticator = await authenticator.authenticators.find(a => a.blockchainLabel === 'neo3') as Neo3Authenticator | undefined

      if (!neoAuthenticator) {
        throw new Error('Neo3 authenticator not found')
      }

      toast({ title: 'Accept the operation on your wallet' })

      const signedMessage = await neoAuthenticator.signMessage('whatever')

      setResp(signedMessage.publicKey)
    } catch (error: any) {
      toast({ title: error.message, status: 'error' })
    } finally {
      setLoading(false)
    }
  }, [getMultiAuthenticator, toast])

  const getFlowWalletProvider = useCallback(async () => {
    try {
      setLoading(true)

      setResp(flowCtx.adapter.getWalletProvider() ?? 'No wallet provider')

    } catch (error: any) {
      toast({ title: error.message, status: 'error' })
    } finally {
      setLoading(false)
    }
  }, [toast, flowCtx.adapter])

  const buyLicenseNFT = useCallback(async () => {
    try {
      const authenticator = await getMultiAuthenticator()

      const [contentProviderId, objectLabel] = await fireSwal({
        title: 'Buy License for Object',
        inputs: [
          { label: 'Content Provider Label', type: 'text' },
          { label: 'Object Label', type: 'text' }
        ],
      })

      setLoading(true)

      toast({ title: 'Accept the operation on your wallet' })

      const tx = await authenticator.buyLicenseNFT(contentProviderId, objectLabel)

      toast({ title: 'We are confirming your transaction' })

      await authenticator.confirmMint(tx)

      await populateTokens()

      setResp(JSON.stringify({ tx }, null, 2))
    } catch (error: any) {
      toast({ title: error.message, status: 'error' })
    } finally {
      setLoading(false)
    }
  }, [getMultiAuthenticator, toast, populateTokens])

  const balanceOf = useCallback(async () => {
    try {
      const authenticator = await getMultiAuthenticator()

      const [accountId, blockchainLabel] = await fireSwal({
        title: 'Balance of',
        inputs: [
          { label: 'Address', type: 'text' },
          {
            label: 'Blockchain  Label',
            type: 'select',
            options: authenticator.authenticators.map(authenticator => authenticator.blockchainLabel),
          },
        ],
      })

      setLoading(true)

      const resp = await authenticator.balanceOf({ accountId, blockchainLabel })

      setResp(`${resp}`)
    } catch (error: any) {
      toast({ title: error.message, status: 'error' })
    } finally {
      setLoading(false)
    }
  }, [getMultiAuthenticator, toast])

  const tokensOf = useCallback(async () => {
    try {
      const authenticator = await getMultiAuthenticator()

      const [accountId, blockchainLabel] = await fireSwal({
        title: 'Tokens of',
        inputs: [
          { label: 'Address', type: 'text' },
          {
            label: 'Blockchain  Label',
            type: 'select',
            options: authenticator.authenticators.map(authenticator => authenticator.blockchainLabel),
          },
        ],
      })

      setLoading(true)

      const tokens = await authenticator.tokensOfAsArray({ accountId, blockchainLabel })

      if (!tokens) return

      const properties: any = {}

      for (const tokenId of tokens) {
        properties[tokenId.id] = await authenticator.properties(tokenId)
      }

      setResp(JSON.stringify({ tokens, properties }, null, 2))
    } catch (error: any) {
      toast({ title: error.message, status: 'error' })
    } finally {
      setLoading(false)
    }
  }, [getMultiAuthenticator, toast])

  const ownerOf = useCallback(async () => {
    try {
      const authenticator = await getMultiAuthenticator()

      const [id, blockchainLabel] = await fireSwal({
        title: 'Owner of',
        inputs: [
          { label: 'Token ID', type: 'text' },
          {
            label: 'Blockchain  Label',
            type: 'select',
            options: authenticator.authenticators.map(authenticator => authenticator.blockchainLabel),
          },
        ],
      })

      setLoading(true)

      const resp = await authenticator.ownerOf({ blockchainLabel, id })

      setResp(JSON.stringify(resp, null, 2))
    } catch (error: any) {
      toast({ title: error.message, status: 'error' })
    } finally {
      setLoading(false)
    }
  }, [getMultiAuthenticator, toast])

  const getCategory = useCallback(async () => {
    try {
      const authenticator = await getMultiAuthenticator()

      const [contentProviderLabel, label] = await fireSwal({
        title: 'Get Category',
        inputs: [
          { label: 'Content Provider Label', type: 'text' },
          {
            label: 'Category Label',
            type: 'text',
          },
        ],
      })

      setLoading(true)

      const category = await authenticator.getCategory(contentProviderLabel, label)

      setResp(JSON.stringify(category, null, 2))
    } catch (error: any) {
      toast({ title: error.message, status: 'error' })
    } finally {
      setLoading(false)
    }
  }, [getMultiAuthenticator, toast])

  const getObject = useCallback(async () => {
    try {
      const authenticator = await getMultiAuthenticator()

      const [contentProviderLabel, label] = await fireSwal({
        title: 'Get Object',
        inputs: [
          { label: 'Content Provider Label', type: 'text' },
          {
            label: 'Object Label',
            type: 'text',
          },
        ],
      })

      setLoading(true)

      const object = await authenticator.getObject(contentProviderLabel, label)

      setResp(JSON.stringify(object, null, 2))
    } catch (error: any) {
      toast({ title: error.message, status: 'error' })
    } finally {
      setLoading(false)
    }
  }, [getMultiAuthenticator, toast])

  const getLicense = useCallback(async () => {
    try {
      const authenticator = await getMultiAuthenticator()

      const [id, blockchainLabel] = await fireSwal({
        title: 'Get License',
        inputs: [
          { label: 'License ID', type: 'text' },
          {
            label: 'Blockchain  Label',
            type: 'select',
            options: authenticator.authenticators.map(authenticator => authenticator.blockchainLabel),
          },
        ],
      })

      setLoading(true)

      const resp = await authenticator.getLicense({ blockchainLabel, id })

      setResp(JSON.stringify(resp, null, 2))
    } catch (error: any) {
      toast({ title: error.message, status: 'error' })
    } finally {
      setLoading(false)
    }
  }, [getMultiAuthenticator, toast])

  const getAccessExpiration = useCallback(async () => {
    try {
      const authenticator = await getMultiAuthenticator()

      const [contentProviderLabel, accountId, blockchainLabel, objectLabel] = await fireSwal({
        title: 'Get Access Expiration',
        inputs: [
          { label: 'Content Provider Label', type: 'text' },
          { label: 'Account ID', type: 'text' },
          {
            label: 'Blockchain  Label',
            type: 'select',
            options: authenticator.authenticators.map(authenticator => authenticator.blockchainLabel),
          },
          { label: 'Object Label', type: 'text' },
        ],
      })

      setLoading(true)

      const resp = await authenticator.getAccessExpiration(
        { accountId, blockchainLabel },
        contentProviderLabel,
        objectLabel
      )

      setResp(resp ? JSON.stringify(resp, null, 2) : 'undefined')
    } catch (error: any) {
      toast({ title: error.message, status: 'error' })
    } finally {
      setLoading(false)
    }
  }, [getMultiAuthenticator, toast])

  const listObjectsOfCategory = useCallback(async () => {
    try {
      const authenticator = await getMultiAuthenticator()

      const [contentProviderLabel, label] = await fireSwal({
        title: 'List Objects of Category',
        inputs: [
          { label: 'Content Provider Label', type: 'text' },
          { label: 'Category Label', type: 'text' },
        ],
      })

      setLoading(true)

      const objects = await authenticator.listObjectsOfCategory(contentProviderLabel, label)

      setResp(JSON.stringify(objects, null, 2))
    } catch (error: any) {
      toast({ title: error.message, status: 'error' })
    } finally {
      setLoading(false)
    }
  }, [getMultiAuthenticator, toast])

  const listCategoriesOfContentProviderAsArray = useCallback(async () => {
    try {
      const authenticator = await getMultiAuthenticator()

      const [contentProviderLabel] = await fireSwal({
        title: 'List Categories of Content Provider',
        inputs: [{ label: 'Content Provider Label', type: 'text' }],
      })

      setLoading(true)

      const resp = await authenticator.listCategoriesOfContentProviderAsArray(contentProviderLabel)

      setResp(JSON.stringify(resp, null, 2))
    } catch (error: any) {
      toast({ title: error.message, status: 'error' })
    } finally {
      setLoading(false)
    }
  }, [getMultiAuthenticator, toast])

  const listObjectsOfContentProviderAsArray = useCallback(async () => {
    try {
      const authenticator = await getMultiAuthenticator()

      const [contentProviderLabel] = await fireSwal({
        title: 'List Objects of Content Provider',
        inputs: [{ label: 'Content Provider Label', type: 'text' }],
      })

      setLoading(true)

      const resp = await authenticator.listObjectsOfContentProviderAsArray(contentProviderLabel)

      setResp(JSON.stringify(resp, null, 2))
    } catch (error: any) {
      toast({ title: error.message, status: 'error' })
    } finally {
      setLoading(false)
    }
  }, [getMultiAuthenticator, toast])

  const hasAccess = useCallback(async () => {
    try {
      const authenticator = await getMultiAuthenticator()

      const [contentProviderLabel, accountAddress, blockchainLabel, objectLabel] = await fireSwal({
        title: 'Has Access',
        inputs: [
          { label: 'Content Provider Label', type: 'text' },
          { label: 'Account Address', type: 'text' },
          {
            label: 'Blockchain  Label',
            type: 'select',
            options: authenticator.authenticators.map(authenticator => authenticator.blockchainLabel),
          },
          { label: 'Object Label', type: 'text' },
        ],
      })

      setLoading(true)

      const resp = await authenticator.hasAccess(
        { accountId: accountAddress, blockchainLabel },
        contentProviderLabel,
        objectLabel
      )

      setResp(resp ? 'Yes' : 'No')
    } catch (error: any) {
      toast({ title: error.message, status: 'error' })
    } finally {
      setLoading(false)
    }
  }, [getMultiAuthenticator, toast])

  const registerCategory = useCallback(async () => {
    try {
      const authenticator = await getMultiAuthenticator()

      const [contentProviderLabel, label, title] = await fireSwal({
        title: 'Register Category',
        inputs: [
          { label: 'Content Provider Label', type: 'text' },
          { label: 'Category Label', type: 'text' },
          { label: 'Category Title', type: 'text' },
        ],
      })

      setLoading(true)

      toast({ title: 'Accept the operation on your wallet' })

      const txs = await authenticator.registerCategory(contentProviderLabel, {
        label,
        title,
      })

      toast({ title: 'We are confirming your transaction' })

      await Promise.all(txs.map(tx => authenticator.confirmRegisterCategory(tx)))

      setResp(JSON.stringify({ txs }, null, 2))
    } catch (error: any) {
      toast({ title: error.message, status: 'error' })
    } finally {
      setLoading(false)
    }
  }, [getMultiAuthenticator, toast])

  const registerObject = useCallback(async () => {
    try {
      const authenticator = await getMultiAuthenticator()

      const [contentProviderLabel, categoryIds, label, metadata] = await fireSwal({
        title: 'Register Object',
        inputs: [
          { label: 'Content Provider Label', type: 'text' },
          { label: 'Category IDs separated by comma', type: 'text' },
          { label: 'Object Label', type: 'text' },
          { label: 'Metadata', type: 'textarea' },
        ],
      })

      const categories = categoryIds.split(',').map(id => id.trim())

      setLoading(true)

      toast({ title: 'Accept the operation on your wallet' })

      const txs = await authenticator.registerObject(contentProviderLabel, {
        categories,
        label,
        codeGenerationUrl: '',
        hiddenMetadata: '',
        metadata: metadata,
        privateInfoUrl: '',
      })

      toast({ title: 'We are confirming your transaction' })

      await Promise.all(txs.map(tx => authenticator.confirmRegisterObject(tx)))

      setResp(JSON.stringify({ txs }, null, 2))
    } catch (error: any) {
      toast({ title: error.message, status: 'error' })
    } finally {
      setLoading(false)
    }
  }, [getMultiAuthenticator, toast])

  const registerContentProvider = useCallback(async () => {
    try {
      const authenticator = await getMultiAuthenticator()

      const blockchains = authenticator.authenticators.map(authenticator => authenticator.blockchainLabel)

      const [contentProviderLabel, ...accountIds] = await fireSwal({
        title: 'Register Content Provider',
        inputs: [
          { label: 'Content Provider Label', type: 'text' },
          ...blockchains.map<TInputText>((b) => ({ label: `Content Provider ${b} Account`, type: 'text' })),
        ],
      })

      setLoading(true)

      toast({ title: 'Accept the operation on your wallet' })

      const txs: TxRef<any>[] = []
      for (let i = 0; i < blockchains.length; i++) {
        txs.push(...await authenticator.registerContentProvider(contentProviderLabel, {
          accountId: accountIds[i],
          blockchainLabel: blockchains[i],
        }))
      }

      toast({ title: 'We are confirming your transaction' })

      await Promise.all(txs.map(tx => authenticator.confirmRegisterContentProvider(tx)))

      await populateContentProviders()

      setResp(JSON.stringify(txs, null, 2))
    } catch (error: any) {
      toast({ title: error.message, status: 'error' })
    } finally {
      setLoading(false)
    }
  }, [getMultiAuthenticator, toast, populateContentProviders])

  const removeContentProvider = useCallback(async () => {
    try {
      const authenticator = await getMultiAuthenticator()

      const [contentProviderLabel] = await fireSwal({
        title: 'Enter the Content Provider Label',
        inputs: [{ label: 'Content Provider Label', type: 'text' }],
      })

      setLoading(true)

      toast({ title: 'Accept the operation on your wallet' })

      const txs = await authenticator.removeContentProvider(contentProviderLabel)

      await populateContentProviders()

      setResp(JSON.stringify({ txs }, null, 2))
    } catch (error: any) {
      toast({ title: error.message, status: 'error' })
    } finally {
      setLoading(false)
    }
  }, [getMultiAuthenticator, toast, populateContentProviders])

  const mintLicenseForObject = useCallback(async () => {
    try {
      const authenticator = await getMultiAuthenticator()

      const [contentProviderId, objectLabel, blockchainLabel, expiration, ownerAddress, image] = await fireSwal({
        title: 'Mint License for Object',
        inputs: [
          { label: 'Content Provider Label', type: 'text' },
          { label: 'Object Label', type: 'text' },
          {
            label: 'Blockchain  Label',
            type: 'select',
            options: authenticator.authenticators.map(authenticator => authenticator.blockchainLabel),
          },
          { label: 'Expiration Date', type: 'date' },
          { label: 'Owner Address', type: 'text' },
          { label: 'Image URL', type: 'text' },
        ],
      })

      setLoading(true)

      toast({ title: 'Accept the operation on your wallet' })

      const tx = await authenticator.mint(contentProviderId, {
        objectLabel,
        expiration: new Date(expiration).getTime(),
        ownerAccountRef: { accountId: ownerAddress, blockchainLabel },
        image,
      })

      toast({ title: 'We are confirming your transaction' })

      await authenticator.confirmMint(tx)

      await populateTokens()

      setResp(JSON.stringify({ tx }, null, 2))
    } catch (error: any) {
      toast({ title: error.message, status: 'error' })
    } finally {
      setLoading(false)
    }
  }, [getMultiAuthenticator, toast, populateTokens])

  const mintLicenseForCategory = useCallback(async () => {
    try {
      const authenticator = await getMultiAuthenticator()

      const [contentProviderId, categoryLabel, blockchainLabel, expiration, ownerAddress, image] = await fireSwal({
        title: 'Mint License for Category',
        inputs: [
          { label: 'Content Provider Label', type: 'text' },
          { label: 'Category Label', type: 'text' },
          {
            label: 'Blockchain  Label',
            type: 'select',
            options: authenticator.authenticators.map(authenticator => authenticator.blockchainLabel),
          },
          { label: 'Expiration Date', type: 'date' },
          { label: 'Owner Address', type: 'text' },
          { label: 'Image URL', type: 'text' },
        ],
      })

      setLoading(true)

      toast({ title: 'Accept the operation on your wallet' })

      const tx = await authenticator.mint(contentProviderId, {
        categoryLabel,
        expiration: new Date(expiration).getTime(),
        ownerAccountRef: { accountId: ownerAddress, blockchainLabel },
        image,
      })

      toast({ title: 'We are confirming your transaction' })

      await authenticator.confirmMint(tx)

      await populateTokens()

      setResp(JSON.stringify({ tx }, null, 2))
    } catch (error: any) {
      toast({ title: error.message, status: 'error' })
    } finally {
      setLoading(false)
    }
  }, [getMultiAuthenticator, toast, populateTokens])

  const transfer = useCallback(async () => {
    try {
      const authenticator = await getMultiAuthenticator()

      const [licenseId, addressTo, blockchainLabel] = await fireSwal({
        title: 'Mint License for Category',
        inputs: [
          { label: 'License ID', type: 'text' },
          { label: 'To (Address)', type: 'text' },
          {
            label: 'Blockchain  Label',
            type: 'select',
            options: authenticator.authenticators.map(authenticator => authenticator.blockchainLabel),
          },
        ],
      })

      setLoading(true)

      toast({ title: 'Accept the operation on your wallet' })

      const tx = await authenticator.transfer(
        { accountId: addressTo, blockchainLabel },
        { id: licenseId, blockchainLabel }
      )

      setResp(JSON.stringify({ tx }, null, 2))
    } catch (error: any) {
      toast({ title: error.message, status: 'error' })
    } finally {
      setLoading(false)
    }
  }, [getMultiAuthenticator, toast])

  const signMessage = useCallback(async () => {
    try {
      const authenticator = await getMultiAuthenticator()

      const [message] = await fireSwal({
        title: 'Sign Message',
        inputs: [{ label: 'Message', type: 'text' }],
      })

      setLoading(true)

      toast({ title: 'Accept the operation on your wallet' })

      const txs = await authenticator.signMessage(message)

      setResp(JSON.stringify({ txs }, null, 2))
    } catch (error: any) {
      toast({ title: error.message, status: 'error' })
    } finally {
      setLoading(false)
    }
  }, [getMultiAuthenticator, toast])

  const verifyMessage = useCallback(async () => {
    try {
      const authenticator = await getMultiAuthenticator()

      const [
        f_type,
        f_vsn,
        addr,
        keyId,
        signature,
        messageHex,
      ] = await fireSwal({
        title: 'Verify Message',
        inputs: [
          { label: 'f_type', type: 'text' },
          { label: 'f_vsn', type: 'text' },
          { label: 'addr', type: 'text' },
          { label: 'keyId', type: 'text' },
          { label: 'signature', type: 'text' },
          { label: 'messageHex', type: 'textarea' },
        ],
      })

      setLoading(true)

      toast({ title: 'Accept the operation on your wallet' })

      const flowSignedMessage = {
        f_type: f_type as string,
        f_vsn: f_vsn as string,
        addr: addr as string,
        keyId: parseInt(keyId as string),
        signature: signature as string,
        messageHex: messageHex as string,
        blockchainLabel: 'flow',
      }

      const txs = await authenticator.verifyMessage(flowSignedMessage)

      setResp(JSON.stringify({ txs }, null, 2))
    } catch (error: any) {
      toast({ title: error.message, status: 'error' })
    } finally {
      setLoading(false)
    }
  }, [getMultiAuthenticator, toast])

  useEffect(() => {
    if (alreadyStarted.current) return

    populateContentProviders()
    populateTokens()
    startListeners()

    alreadyStarted.current = true
  }, [populateContentProviders, populateTokens, startListeners])

  return (
    <Box p={'10px'}>
      <Heading mb={'8px'}>CMS</Heading>

      <Flex mt={8}>
        <Box flex={1}>
          <Heading size={'md'} mb={'1rem'}>Operations</Heading>

          <Accordion defaultIndex={[0]} allowMultiple>
            <AccordionItem>
              <AccordionButton><Box flex='1' fontWeight={'bold'}>
                Authentication
              </Box><AccordionIcon /></AccordionButton>
              <AccordionPanel pb={4}>
                {walletConnectCtx.isConnected() && <Box>Neo Address: {walletConnectCtx.getAccountAddress()}</Box>}
                {flowCtx.isAuthenticated && <Box>Flow Address: {flowCtx.address}</Box>}

                <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
                  {!walletConnectCtx.isConnected() ? (
                    <Button onClick={() => walletConnectCtx.connect(NETWORK.WcIdentifier)}>
                      Connect with Neo3
                    </Button>
                  ) : (
                    <Button onClick={walletConnectCtx.disconnect}>
                      Disconnect from Neo3
                    </Button>
                  )}

                  {!flowCtx.isAuthenticated ? (
                    <Button onClick={flowCtx.connect}>
                      Connect with Flow
                    </Button>
                  ) : (
                    <Button onClick={flowCtx.disconnect}>
                      Disconnect from Flow
                    </Button>
                  )}
                  {walletConnectCtx.isConnected() && (
                    <>
                      <Button onClick={getNeo3PublicKey}>
                        Get Neo3 Public Key
                      </Button>
                    </>
                  )}
                  {flowCtx.isAuthenticated && (
                    <>
                      <Button onClick={getFlowWalletProvider}>
                        Get Flow Wallet Provider
                      </Button>
                    </>
                  )}
                </SimpleGrid>
              </AccordionPanel>
            </AccordionItem>

            {(walletConnectCtx.isConnected() || flowCtx.isAuthenticated) && (
              <AccordionItem>
                <AccordionButton><Box flex='1' fontWeight={'bold'}>
                  Prepare Account
                </Box><AccordionIcon /></AccordionButton>
                <AccordionPanel pb={4}>
                  <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4} mb={4}>
                    <Button onClick={prepareAdmin}>
                      Prepare Admin
                    </Button>
                    <Button onClick={prepareContentProvider}>
                      Prepare Content Provider
                    </Button>

                    <Button onClick={prepareNFTOwner}>
                      Prepare NFT Owner
                    </Button>
                    <Button onClick={destroyAdminContainer}>
                      Destroy Admin Container
                    </Button>
                    <Button onClick={destroyCpCollection}>
                      Destroy CP Collection
                    </Button>
                    <Button onClick={destroyLicenseCollection}>
                      Destroy License Collection
                    </Button>
                  </SimpleGrid>
                </AccordionPanel>
              </AccordionItem>
            )}

            <AccordionItem>
              <AccordionButton><Box flex='1' fontWeight={'bold'}>
                Read Content Info
              </Box><AccordionIcon /></AccordionButton>
              <AccordionPanel pb={4}>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4} mb={4}>
                  <Button onClick={getCategory}>Get Category</Button>
                  <Button onClick={getObject}>Get Object</Button>
                  <Button onClick={listObjectsOfCategory}>List Objects of Category</Button>
                  <Button onClick={hasAccess}>Has Access</Button>
                  <Button onClick={listObjectsOfContentProviderAsArray}>List Objects of Content Provider</Button>
                  <Button onClick={listCategoriesOfContentProviderAsArray}>List Categories of Content Provider</Button>
                </SimpleGrid>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionButton><Box flex='1' fontWeight={'bold'}>
                Read License Info
              </Box><AccordionIcon /></AccordionButton>
              <AccordionPanel pb={4}>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4} mb={4}>
                  <Button onClick={balanceOf}>Balance of</Button>
                  <Button onClick={tokensOf}>Tokens of + Properties</Button>
                  <Button onClick={ownerOf}>Owner of</Button>
                  <Button onClick={getLicense}>Get License</Button>
                  <Button onClick={getAccessExpiration}>Get Access Expiration</Button>
                </SimpleGrid>
              </AccordionPanel>
            </AccordionItem>

            {(walletConnectCtx.isConnected() || flowCtx.isAuthenticated) && (
              <>
                <AccordionItem>
                  <AccordionButton><Box flex='1' fontWeight={'bold'}>
                    Admin Operations
                  </Box><AccordionIcon /></AccordionButton>
                  <AccordionPanel pb={4}>
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4} mb={4}>
                      <Button onClick={registerContentProvider}>Register Content Provider</Button>
                      <Button onClick={removeContentProvider}>Remove Content Provider</Button>
                      <Button onClick={registerAdminAddresses}>Register Admin Addresses</Button>
                    </SimpleGrid>
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionButton><Box flex='1' fontWeight={'bold'}>
                    Content Provider Operations
                  </Box><AccordionIcon /></AccordionButton>
                  <AccordionPanel pb={4}>
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4} mb={4}>
                      <Button onClick={registerCategory}>Register Category</Button>
                      <Button onClick={registerObject}>Register Object</Button>
                      <Button onClick={mintLicenseForObject}>Mint License for Object</Button>
                      <Button onClick={mintLicenseForCategory}>Mint License for Category</Button>
                    </SimpleGrid>
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionButton><Box flex='1' fontWeight={'bold'}>
                    User Operations
                  </Box><AccordionIcon /></AccordionButton>
                  <AccordionPanel pb={4}>
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4} mb={4}>
                      <Button onClick={transfer}>Transfer</Button>
                      <Button onClick={signMessage}>Sign Message</Button>
                      <Button onClick={verifyMessage}>Verify Message</Button>
                      <Button onClick={buyLicenseNFT}>Buy License NFT</Button>
                    </SimpleGrid>
                  </AccordionPanel>
                </AccordionItem>
              </>
            )}

          </Accordion>

          <Modal size={'xl'} isOpen={Boolean(resp.length)} onClose={() => setResp('')}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Modal Title</ModalHeader>
              <ModalCloseButton />
              <ModalBody p={'10px'}>
                <Box as={'pre'} bg={'#404550'} color={'white'} p={'20px'} borderRadius={'24px'} overflowX={'auto'}>
                  {resp}
                </Box>
              </ModalBody>
            </ModalContent>
          </Modal>

        </Box>

        <Box w={'300px'} ml={'16px'}>
          <Heading size={'md'} mb={'1rem'}>Data</Heading>

          <Accordion defaultIndex={[0]} allowMultiple>
            <AccordionItem>
              <AccordionButton><Box flex='1' fontWeight={'bold'}>
                Content Providers
              </Box><AccordionIcon /></AccordionButton>
              <AccordionPanel pb={4}>
                <Box>
                  {contentProviders &&
                    contentProviders.map((cp, i) => (
                      <Box key={i} mb={'4px'} borderWidth={1} p={2}>
                        {cp}
                      </Box>
                    ))}
                </Box>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionButton><Box flex='1' fontWeight={'bold'}>
                Tokens
              </Box><AccordionIcon /></AccordionButton>
              <AccordionPanel pb={4}>
                <Flex gridRowGap="8px" gridColumnGap="8px" wrap="wrap">
                  {allTokens.map((token, i) => (
                    <Text key={i} fontSize="12px" border="1px solid black" p="4px" borderRadius="full">
                      {token}
                    </Text>
                  ))}
                </Flex>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
      </Flex>

      {loading && (
        <Spinner position={'fixed'} size={'xl'} bottom={'2rem'} right={'2rem'} />
      )}
    </Box>
  )
}
